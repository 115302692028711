import cx from 'classnames'
import { ReactNode } from 'react'

import { Text } from '@mondough/monzo-ui'

import styles from './Footer.module.scss'

export function Footer({
  className,
  links,
}: {
  className?: string
  links?: ReactNode[]
}) {
  const defaultLinks = [
    <a
      href="https://monzo.com/legal/privacy-notice"
      target="_blank"
      rel="noreferrer"
      key="privacy"
    >
      Privacy notice
    </a>,
    <a
      href="https://monzo.com/legal/cookie-notice"
      target="_blank"
      rel="noreferrer"
      key="cookie"
    >
      Cookie notice
    </a>,
    <a
      href="https://monzo.com/legal/terms-and-conditions/"
      target="_blank"
      rel="noreferrer"
      key="t&cs"
    >
      Terms & Conditions
    </a>,
  ]

  const linksToUse = links || defaultLinks
  return (
    <footer className={cx(styles.footer, className)}>
      <div className={styles.inner}>
        <div className={styles.main}>
          <section className={styles.links}>
            {linksToUse.map((link, i) => (
              <Text
                key={`link_${i}`}
                color="inverted"
                marginBottom="medium"
                className={styles.links}
              >
                {link}
              </Text>
            ))}
          </section>
          <Text color="inverted">
            Monzo Bank Limited is a company registered in England and Wales (No.
            09446231). Monzo Bank Ltd is authorised by the Prudential Regulation
            Authority (PRA) and regulated by the Financial Conduct Authority and
            the Prudential Regulation Authority (PRA). Our Financial Services
            Register number is 730427.
          </Text>
        </div>
      </div>
    </footer>
  )
}
