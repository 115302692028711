import cx from 'classnames'
import * as React from 'react'

import { Error } from '../../../types/error'
import { copy } from '../copy'
import styles from './ErrorBanner.module.scss'

type Props = {
  className?: string
  error?: Error | null
  onBannerClick?: () => void
}

export const ErrorBanner = (props: Props) => {
  const { error, className, onBannerClick } = props
  const shouldDisplayBanner = !!error
  const isFatal = error?.fatal

  const handleDismissError = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()

    if (isFatal) {
      if (typeof window !== 'undefined') window.location.reload()
    } else {
      onBannerClick?.()
    }
    document.body.classList.remove(styles['no-scroll'])
  }

  React.useEffect(() => {
    if (isFatal) {
      document.body.classList.add(styles['no-scroll'])
    }
    return () => {
      document.body.classList.remove(styles['no-scroll'])
    }
  }, [isFatal])

  return shouldDisplayBanner ? (
    <div
      role="alert"
      className={cx(
        styles['error-banner-wrapper'],
        { [styles['fatal-error-banner-wrapper']]: isFatal },
        className,
      )}
      onClick={handleDismissError}
    >
      {error?.defaultMessage || copy.errorBanner.defaultMessage}
    </div>
  ) : null
}
