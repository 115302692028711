var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"245a0642194783904f530b32bd8d67e617d1cbee"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init as initSentry } from '@sentry/nextjs'
import getConfig from 'next/config'

import {
  captureException,
  sanitizeBreadcrumb,
  sanitizeEvent,
} from '@mondough/error-handling'

import { sanitizeURL } from './utils/sanitization'

const { publicRuntimeConfig } = getConfig()
const { environment, sentryDSN, sentryOrg, sentryProject } = publicRuntimeConfig
const { NEXT_PUBLIC_GIT_COMMIT } = process.env

const BLOCKLIST: string[] = []
const IGNORE_ERRORS: string[] = []

const bootstrap = () => {
  if (!Boolean(environment)) {
    console.warn('Environment not set')
    return
  }

  if (environment === 'local') {
    console.warn('Sentry not enabled in local environment')
    return
  }

  if (!Boolean(sentryDSN)) {
    console.warn('Sentry DSN environment variable not set')
    return
  }

  if (!Boolean(sentryOrg)) {
    console.warn('Sentry Org environment variable not set')
    return
  }

  if (!Boolean(sentryProject)) {
    console.warn('Sentry Project environment variable not set')
    return
  }

  if (!Boolean(NEXT_PUBLIC_GIT_COMMIT)) {
    console.warn('Git commit not set')
    return
  }

  const date = new Date(Date.now())
  const formattedDate = date.toLocaleDateString('en-GB')
  const releaseTag = NEXT_PUBLIC_GIT_COMMIT ?? `local-${formattedDate}`

  console.info(`Git Commit: ${releaseTag}`)

  initSentry({
    dsn: sentryDSN,
    tracePropagationTargets: [
      // Sentry Defaults
      'localhost',
      /^\//,
      // Pay Anyone Specific
      /^https:\/\/pay.monzo.com/,
      /^https:\/\/pay-s101.monzo.com/,
    ],
    release: releaseTag,
    beforeBreadcrumb: sanitizeBreadcrumb(BLOCKLIST, sanitizeURL),
    // @ts-ignore
    beforeSend: (event, _) => {
      // Group errors by value (error message), regardless of the stack trace
      if (event.exception) {
        const exceptionValue =
          event.exception?.values?.[0]?.value ?? 'undefined'
        event.fingerprint = ['{{ default }}', exceptionValue]
      }
      // @ts-ignore sanitizeEvent expects a SentryEvent
      return sanitizeEvent(BLOCKLIST)(event)
    },
    ignoreErrors: IGNORE_ERRORS,
    environment: environment,
    initialScope: { tags: { component_name: 'app.pay-anyone' } },
  })

  window.onunhandledrejection = (e) => captureException(e.reason)
}

bootstrap()
