import { useAppContext } from '../AppContext'
import { Loading } from '../components/reskin'
import NotFoundPageControl from '../page-layouts/control/404'
import AmountPageControl from '../page-layouts/control/index'
import AmountPageReskin from '../page-layouts/reskin-common/index'
import { UI_VARIANTS } from '../types/payment'

export const AmountPage = () => {
  const { payment, errors } = useAppContext()

  if (errors?.notFound) {
    return <NotFoundPageControl />
  }

  if (!payment) {
    return <Loading />
  }

  const UIVariant = payment.ui_variant || UI_VARIANTS.CONTROL

  switch (UIVariant) {
    case UI_VARIANTS.CONTROL:
      return <AmountPageControl />
    case UI_VARIANTS.RESKIN:
      return <AmountPageReskin />
    case UI_VARIANTS.JOB_FOCUSED:
      return (
        <AmountPageReskin displayDownloadLink={false} displayReferral={false} />
      )
    case UI_VARIANTS.CONTENT_HIERARCHY:
      return (
        <AmountPageReskin
          displayDownloadLink={false}
          simplifiedReferral={true}
        />
      )
    default:
      return null
  }
}

export default AmountPage
