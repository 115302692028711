import React from 'react'

import { MoneyValue } from '@mondough/money'
import { Money, Text } from '@mondough/monzo-ui'

import Avatar from '../../Avatar'
import styles from './PayerDetails.module.scss'

type Props = {
  userId: string
  name: string
  requestedAmount: MoneyValue
  note?: string | null
}

export const PayerDetails = ({
  userId,
  name,
  requestedAmount,
  note,
}: Props) => {
  return (
    <div className={styles.container}>
      <Avatar userId={userId} name={name} />
      <div className={styles.text}>
        <Text className={styles.name} size="large" bold>
          {name}
        </Text>
        {note != null && note !== '' && (
          <Text className={styles.note}>{note}</Text>
        )}
      </div>
      <Money
        className={styles.amount}
        majorClassName={styles.major}
        minorClassName={styles.minor}
        symbolClassName={styles.symbol}
        amount={requestedAmount}
      />
    </div>
  )
}
