import cookies from 'cookies-js'
import store from 'store2'
import uuid from 'uuid'

import { apiRequest } from './auth'
import mondoApi from './mondoSdk'

const getSessionID = () => {
  let sessionID = getItem('sessionId')

  if (sessionID) return sessionID

  sessionID = uuid.v4()
  setItem('sessionId', sessionID)
  return sessionID
}

export const trackEvent = async (
  events: {
    name: string
    data?: Record<string, unknown>
  }[],
  trackPageView = false,
) => {
  const sessionID = getSessionID()
  const urlPieces = [
    window.location.protocol,
    '//',
    window.location.host,
    window.location.pathname,
  ] // we don't want to include the hash code in the URL
  const url = urlPieces.join('')
  const mappedEvents = events.map(({ name, data }) => ({
    name,
    data: augmentData(data),
  }))
  const allEvents = trackPageView
    ? [{ name: 'pageview', data: { url } }, ...mappedEvents]
    : mappedEvents

  await apiRequest(
    mondoApi.trackAnalyticsEvent,
    {
      events: allEvents,
    },
    {
      'Mondo-Session': sessionID,
    },
  )
}

function augmentData(data = {}) {
  return {
    ...data,
    referrer: document.referrer,
    user_languages: navigator.languages || [navigator.language],
  }
}

function setItem(k: string, v: string) {
  const storage = store.local
  // write to both local storage and cookies so we can track sessions
  // between iOS Safari and SFSafariViewController (which share cookies
  // but not local storage)
  storage.set(`mondoAnalytics:${k}`, v)
  cookies.set(`mondoAnalytics:${k}`, v, { expires: Infinity, secure: true })
}

function getItem(k: string) {
  const storage = store.local
  let result

  result = storage.get(`mondoAnalytics:${k}`)
  if (result != null) {
    // found key in local storage, copy to cookie
    cookies.set(`mondoAnalytics:${k}`, result, {
      expires: Infinity,
      secure: true,
    })
  } else {
    result = cookies.get(`mondoAnalytics:${k}`)
    if (result != null) {
      // found key in cookie, copy to local storage
      storage.set(`mondoAnalytics:${k}`, result)
    }
  }

  return result || ''
}
