import { Text, Title } from '@mondough/monzo-ui'

import { Referral } from '../../../types/payment'
import { trackEvent } from '../../../utils/analytics'
import { Button } from '../'
import styles from './ReferralPrompt.module.scss'

export function ReferralPrompt({
  referral,
  simplifiedCopy,
  isCtaPrimary,
}: {
  referral?: Referral
  simplifiedCopy?: boolean
  isCtaPrimary?: boolean
}) {
  if (!referral) {
    return null
  }
  const referralCopyArray = referral.description.split('\n')
  return (
    <aside className={styles.aside}>
      <div className={styles.beach}>&nbsp;</div>
      <Title is="h2">{referral.title}</Title>
      {simplifiedCopy
        ? null
        : referralCopyArray.map(
            (line) =>
              line && (
                <Text
                  key={line}
                  color="primary"
                  size="large"
                  className={styles['text']}
                >
                  {line}
                </Text>
              ),
          )}
      {referral.legal && (
        <Text color="primary" size="large" className={styles['text']}>
          {referral.legal}
        </Text>
      )}
      <Button
        theme={isCtaPrimary ? 'primary' : 'secondary'}
        href={referral.button_url}
        onClick={() => {
          trackEvent([
            {
              name: 'pay-anyone.web-referral-prompt.click',
              data: {
                link: referral.button_url,
              },
            },
          ])
        }}
        className={styles['join-monzo-button']}
        target="_blank"
        rel="noreferrer"
      >
        {referral.button}
      </Button>
    </aside>
  )
}
