const Logo = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 72 73"
    >
      <path
        fill="url(#a1)"
        d="M14 45.7c0 .6.2 1.1.5 1.5l9.2 9.2.4.2h.5a.8.8 0 0 0 .5-.8V35.2l-11-11-.1-.1v21.6Z"
      />
      <path
        fill="#48D3B2"
        d="M35.8 30.4 22.3 16.6l-.3-.2a.8.8 0 0 0-.6 0 1 1 0 0 0-.3.2l-7 7.1c-.2.2-.2.5 0 .7l10.9 11L35.5 46l.5.2V30.4h-.2Z"
      />
      <path
        fill="url(#b2)"
        d="m58 24-.1.1-11 11v20.7a.8.8 0 0 0 1 .8l.4-.2 9.1-9.2c.4-.4.6-1 .6-1.5V24.1Z"
      />
      <path
        fill="#BBF42E"
        d="m57.9 23.7-7-7-.3-.3a.8.8 0 0 0-.6 0 1 1 0 0 0-.3.2L36.1 30.4H36v15.8l.5-.2L47 35.4l10.9-11c.2-.2.2-.5 0-.7Z"
      />
      <defs>
        <linearGradient
          id="a1"
          x1="22.4"
          x2="24.7"
          y1="37"
          y2="34.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".2" stopColor="#0088A6" />
          <stop offset=".5" stopColor="#00819F" />
          <stop offset="1" stopColor="#00728E" />
        </linearGradient>
        <linearGradient
          id="b2"
          x1="49.5"
          x2="47.2"
          y1="37"
          y2="34.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".2" stopColor="#FF4F40" />
          <stop offset=".5" stopColor="#F7493E" />
          <stop offset=".9" stopColor="#E33739" />
          <stop offset="1" stopColor="#DF3438" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default Logo
