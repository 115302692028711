import cx from 'classnames'

import { Text, Title } from '@mondough/monzo-ui'

import { trackEvent } from '../../../utils/analytics'
import { Button } from '../'
import { copy } from '../copy'
import styles from './ABitAboutMonzo.module.scss'

const onButtonClick = () => {
  trackEvent([
    {
      name: 'pay-anyone.web-about-monzo.learn-more-click',
    },
  ])
}

export function ABitAboutMonzo({
  displayAsAside,
}: {
  displayAsAside?: boolean
}) {
  return (
    <div
      className={cx(styles['copy-blocks'], displayAsAside && styles['aside'])}
    >
      <img src="/assets/reskin/smile-box.svg" alt="" className={styles.smile} />
      <Title is="h2">{copy.aboutMonzo.title}</Title>
      <Text>{copy.aboutMonzo.text}</Text>
      <Button
        theme="secondary"
        href="https://monzo.com"
        target="_blank"
        rel="noreferrer"
        size="regular"
        className={styles['learn-more']}
        onClick={onButtonClick}
      >
        {copy.aboutMonzo.learnMore}
      </Button>
    </div>
  )
}
