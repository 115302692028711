import React from 'react'

import Amount from '../../components/Amount'
import Avatar from '../../components/Avatar'
import { Layout } from '../../components/Layout'
import styles from '../../components/PaymentDescription/PaymentDescription.module.scss'

const NotFoundPage = () => {
  return (
    <Layout>
      <div className={styles.profile}>
        <Avatar userId={'not_found'} name={'?'} />
        <div className={styles.amount}>
          <Amount amount={'404'} symbol={''} />
        </div>
        <div className={styles.description}>Payment not found</div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
