import { useRouter } from 'next/router'

import * as Money from '@mondough/money'
import { PlainCard, Text } from '@mondough/monzo-ui'

import { useAppContext } from '../../AppContext'
import Avatar from '../../components/Avatar'
import { Button, Loading, StatefulLayout } from '../../components/reskin'
import { LayoutElementProps } from '../../components/reskin/Layout/Layout'
import { copy } from '../../components/reskin/copy'
import usePageTracking from '../../enhancers/usePageTracking'
import { paymentCompleted } from '../../utils/payment'
import styles from './home.module.scss'

export default function InitialPage(props: LayoutElementProps) {
  const { payment, status, code } = useAppContext()
  const router = useRouter()

  usePageTracking({
    event: 'pay-anyone.web-page.home',
    payment,
  })

  if (!payment) {
    return <Loading />
  }

  const payerName = payment.sender_full_name
  const amount = Money.fromMinorUnits(
    payment.currency as string,
    payment.amount as number,
  )
  const notes = payment.notes

  const isCtaDisabled = paymentCompleted(status)

  return (
    <StatefulLayout {...props}>
      <PlainCard className={styles.wrapper}>
        <Avatar name={payerName} userId={payment.sender_user_id} />
        <Text is="h1" size="large" align="center" bold className={styles.title}>
          {payerName}
          {copy.pages.home.card.title}
          {amount.format()}
        </Text>
        {notes && <Text className={styles.notes}>{notes}</Text>}
        <Button
          theme="primary"
          fullWidth
          onClick={async () => {
            await router.push(`/claim#${code}`)
          }}
          disabled={isCtaDisabled}
        >
          {copy.pages.home.card.cta}
        </Button>
        <Text className={styles['helper-text']}>
          {copy.statusHelpers[status]}
        </Text>
      </PlainCard>
    </StatefulLayout>
  )
}
