import React from 'react'

import type { Payment, PaymentStatus } from '../../types/payment'
import { paymentCompleted } from '../../utils/payment'
import LinkAsButton from '../LinkAsButton'
import PaymentCompleted from '../PaymentCompleted'
import SignupBonusBanner from '../SignupBonusBanner'
import styles from './Claim.module.scss'

type Props = {
  payment: Payment
  code: string
  status: PaymentStatus
}

export default function Claim({ payment, status, code }: Props) {
  const {
    fps_enabled: fpsEnabled,
    signup_bonus: signupBonus,
    claim_type: claimType,
  } = payment

  if (paymentCompleted(status)) {
    return (
      <PaymentCompleted status={status} code={code} claimType={claimType} />
    )
  }

  const message = fpsEnabled
    ? 'Put the money in any UK bank account, or claim it by signing up to Monzo in 5 minutes.'
    : 'Sign up to Monzo to claim your money. It only takes a few minutes.'

  return (
    <section>
      <SignupBonusBanner amount={signupBonus} />
      <p className={styles['claim-description']}>{message}</p>
      <div className={styles.buttons}>
        {fpsEnabled && (
          <LinkAsButton
            to={`/claim/another-account#${code}`}
            buttonStyle="primary"
            className={styles['fps-button']}
            data-testid="fps-button"
          >
            Enter your bank details
          </LinkAsButton>
        )}

        <LinkAsButton to={`/claim/sign-up#${code}`} buttonStyle="primary">
          Sign up to Monzo
        </LinkAsButton>
        <div className={styles['learn-more-link']}>
          <a
            href="https://monzo.com/?utm_source=payanyone&utm_medium=website"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about Monzo
          </a>
        </div>
      </div>
    </section>
  )
}
