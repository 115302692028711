import cx from 'classnames'
import React from 'react'
import { CSSProperties } from 'react'

import { LoadingSpinner } from '@mondough/monzo-ui'

import styles from './Loading.module.scss'

export interface LoadingProps {
  fullScreen?: boolean
  height?: CSSProperties['height']
  spinnerSize?: 48 | 18 | 20 | 24 | 40 | 64 | undefined
}

export const Loading: React.FC<LoadingProps> = ({
  fullScreen = true,
  height,
  spinnerSize = 48,
}) => (
  <div
    className={cx([styles.loading, { [styles.fullscreen]: fullScreen }])}
    style={{ height: height ?? 'auto' }}
  >
    <LoadingSpinner size={spinnerSize} />
  </div>
)
