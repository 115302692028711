import { captureException } from '@mondough/error-handling'

import { Error } from '../types/error'

export const setAPICallError = (
  error: globalThis.Error & {
    errorCode?: string
  },
  setError: (error: Error) => void,
) => {
  captureException(error)
  if (error.errorCode === 'not_found.payment') {
    return setError({
      notFound: true,
      defaultMessage: 'Sorry, something went wrong! Please try again.',
    })
  }
  if (error.errorCode === 'bad_request.email_already_used') {
    return setError({
      error: error.errorCode,
      defaultMessage: 'This e-mail is already associated with a Monzo account',
    })
  }
  if (error.errorCode === 'bad_request.already_claimed') {
    return setError({
      error: error.errorCode,
      defaultMessage: 'This code was already claimed',
    })
  }
  if (!error.errorCode) {
    return setError({
      fatal: true,
    })
  }
  return setError({
    error: error.errorCode,
    defaultMessage: 'Sorry, something went wrong! Please try again.',
  })
}

export const resetError = (setError: (error: Error | null) => void) => {
  setError(null)
}
