import classNames from 'classnames/bind'
import { default as QRCode } from 'react-qr-code'

import Logo from './Logo'
import styles from './QRCode.module.scss'

const cx = classNames.bind(styles)

interface QRCodeBlockProps {
  value: string
  ariaLabel?: string
  theme?: 'light' | 'dark'
  size?: number
  level?: 'L' | 'M' | 'Q' | 'H'
}

/**
 *
 * @param theme - 'light' | 'dark' - Whether the inner code blocks should be light or dark
 * @param alt - description of what the contents of the QR code does so users with assistive tech know
 * @param value - string - The value to encode in the QR code
 * @param size - number - The height and width of the QR code in pixels
 * @param level - 'L'|'M'|'Q'|'H' - The error correction level of the QR code - L is lowest (7%), H is highest (30%)
 * @returns
 */
function QRCodeBlock({
  value,
  ariaLabel,
  theme = 'light',
  size = 320,
  level = 'L',
}: QRCodeBlockProps) {
  const backgroundStyles = cx(['container', theme])
  const foregroundColour = theme === 'light' ? '#091723' : '#ffffff'

  return (
    <div className={backgroundStyles}>
      <div className={styles.mask}>
        <QRCode
          role="img"
          aria-label={ariaLabel}
          aria-hidden={ariaLabel == null}
          value={value}
          bgColor={'none'}
          fgColor={foregroundColour}
          size={size}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          viewBox={`0 0 ${size} ${size}`}
          level={level}
        />
      </div>
      <Logo className={styles.logoM} />
    </div>
  )
}

export default QRCodeBlock
