// @flow

import cx from 'classnames'

import styles from './Logos.module.scss'

export function Logos({ className }: { className?: string }) {
  return (
    <div className={cx(styles.logos, className)}>
      {['bba', 'cass', 'fscs'].map((img) => (
        <img
          key={img}
          src={`/assets/reskin/${img}.png`}
          className={styles[`logo-${img}`]}
          alt=""
        />
      ))}
    </div>
  )
}
