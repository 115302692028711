import cx from 'classnames'
import Link from 'next/link'
import { ReactNode, useEffect, useState } from 'react'

import { ButtonProps } from '@mondough/monzo-ui'

import { Error } from '../../../types/error'
import { Referral } from '../../../types/payment'
import { trackEvent } from '../../../utils/analytics'
import { copy } from '../copy'
import styles from './Header.module.scss'
import { Button } from '..'

export type LayoutElementProps = {
  children?: ReactNode
  displayDownloadLink?: boolean
  className?: string
  referral?: Referral
  displayReferral?: boolean
  simplifiedReferral?: boolean
  onErrorDismissal?: () => void
  error?: Error | null
}

const onLogoClick = () => {
  trackEvent([
    {
      name: 'pay-anyone.web-header.logo-click',
    },
  ])
}

const onDownloadButtonClick = () => {
  trackEvent([
    {
      name: 'pay-anyone.web-header.download-click',
    },
  ])
}

export const MobileAppDownloadLink = ({ id }: { id?: string }) => {
  const [os, setOs] = useState('')
  useEffect(() => {
    const ua = navigator.userAgent
    if (ua.match(/iPhone|iPad|iPod|AppleCoreMedia/i)) {
      setOs('iOS')
    } else if (ua.match(/Android/i)) {
      setOs('AndroidOS')
    }
  }, [])

  let href = ''
  switch (os) {
    case 'iOS':
      href = 'https://itunes.apple.com/gb/app/mondo/id1052238659'
      break
    case 'AndroidOS':
      href =
        'https://play.google.com/store/apps/details?id=co.uk.getmondo&hl=en_GB&utm_source=payanyone&utm_medium=website'
      break
    default:
      href =
        'https://monzo.com/download/?utm_source=payanyone&utm_medium=website'
  }
  const defaultProps: ButtonProps = {
    href: 'https://monzo.com/download/?utm_source=payanyone&utm_medium=website',
    className: styles['download-button'],
    rel: 'noreferrer',
    target: '_blank',
    theme: 'tertiary',
    size: 'small',
  }

  return (
    <Button
      {...defaultProps}
      id={id}
      href={href}
      onClick={onDownloadButtonClick}
    >
      {copy.header.cta.title}
    </Button>
  )
}

export function HeaderLeft({
  children,
  className,
  showLogo = true,
  displayDownloadLink = true,
}: LayoutElementProps & {
  showLogo?: boolean
}) {
  return (
    <header className={cx(styles['header-left'], className)}>
      {showLogo && (
        <>
          <Link href="https://monzo.com">
            <a onClick={onLogoClick} target="_blank" rel="noreferrer">
              <img src="/assets/reskin/logo.svg" alt="monzo" />
            </a>
          </Link>
          {displayDownloadLink && (
            <MobileAppDownloadLink id="header-left-button" />
          )}
        </>
      )}
      {children}
    </header>
  )
}

export function HeaderRight({
  className,
  displayDownloadLink = true,
}: LayoutElementProps) {
  return (
    <div className={cx(styles['header-right'], className)}>
      {displayDownloadLink ? (
        <MobileAppDownloadLink id="header-right-button" />
      ) : null}
    </div>
  )
}
