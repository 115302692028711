import React from 'react'

import { useAppContext } from '../../AppContext'
import Claim from '../../components/Claim/Claim'
import { Layout } from '../../components/Layout'
import { PaymentDescription } from '../../components/PaymentDescription'
import usePageTracking from '../../enhancers/usePageTracking'
import NotFound from './404'

export const AmountPage = () => {
  const { code, payment, status, errors } = useAppContext()
  usePageTracking({ event: 'pay-anyone.web-page.show', payment })
  const { notFound } = errors || {}

  if (notFound) {
    return <NotFound />
  }
  if (payment == null) {
    return null
  }

  return (
    <Layout>
      <PaymentDescription payment={payment} />
      <Claim payment={payment} code={code} status={status} />
    </Layout>
  )
}

export default AmountPage
